@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.instructions{
    background-color: rgb(0,134,208);
    display: flex;
    height: 100vh;
    font-family: "Open Sans", sans-serif;
    font-size: 15pt;
    color: rgb(255, 255, 255);
    font-weight:bold;
    font-style: normal;
    text-align: left;
    padding: 5px;
    line-height: 1.2;
    overflow-y: hidden;
}

.instructions-container{
    padding: 40px;
}

.instruction-card{
   padding: 40px;
}

.instruction-card p{
    padding: 10px;
}

.instruction-card li{
    padding: 10px;
}

.left-arrow{
    width: 70px;
    height: 70px;
    background-image: url(../../../public/svg/white-arrow.svg);
}

.left-arrow:hover{
    width: 70px;
    height: 70px;
    background-image: url(../../../public/svg/white-arrow.svg);
}