.blue-button-login {
    background-color: rgb(0, 134, 208);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border-color: rgb(0, 113, 176);
    border-style: solid;
    border-width: 2px;
    border-radius: 10px;
    margin: -1px;
    margin-left: 67px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 320px;
}

.image {
    width: 400px;
    height: 300px;
    padding: 40px;
}

.login-component {
    background: rgb(219, 219, 219) !important;
    height: 100vh !important;
}