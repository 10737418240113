.collections{
    height: 100vh;
    background-color: rgb(219,219,219);
    display: flex;
    width: 100%;
}

.collections-container{
    padding: 40px;
}

.blue-left-arrow{
    width: 70px;
    height: 70px;
    background-image: url(../../../public/svg/blue-left-arrow.svg);
}

label{
    width: 198px;
    height: 3.5rem;
    font-family: "Open Sans", sans-serif;
    font-size: 20pt;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-style: normal;
    text-align: left;
    line-height: 1.2;
    overflow-y: hidden;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.image-small{
    width: 400px;
    height: 300px;
    position: absolute;
    left:64rem;
    top:7rem;

}

input{
    width: 19rem;
    height: 45px;
    background-color: rgb(255, 255, 255);
    border-style: solid;
    border-width: 2px;
    border-color: rgb(0, 18, 107);
    border-radius: 5px;
    margin: -1px;
    margin: 10px;
    margin-left: 70px;
    font-size: 15px;
    padding-left: 10px;
}

input:hover{
    background-color: rgb(186, 202, 266);
}

input:focus{
    border-width: 4px;
}

.padding{
    padding: 10px;
}

.textarea{
    width: 70rem;
    height: 150px;
    background-color: rgb(255, 255, 255);
    border-style: solid;
    border-width: 2px;
    border-color: rgb(0, 18, 107);
    margin: -1px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10rem;
}

.font{
    font-family: "Open Sans", sans-serif;
    font-size: 21pt;
    color: rgb(0, 0, 0);
    font-weight: normal;
    font-style: normal;
    text-align: center;
    padding: 5px;
    line-height: 1.2;
    overflow-y: hidden;
}

.flex{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.collections-header{
    width: 100%;
}

.form{
 margin-top: 60px;
 margin-left: 70px;
}

.direction{
    justify-content: flex-start;
}


table {
    border-collapse: collapse;
    width: 50%;
  }
  
  table, td, th {
    border: 1px solid #ddd;
  }
  
  .padding {
    padding: 10px; /* Add padding as needed */
  }
  
  .width {
    width: 100%; /* Make the table occupy full width */
  }
  
  .flex-container {
    display: flex;
    justify-content:flex-start; /* Arrange label and input next to each other */
  }

  .placeholder{
    width: 35rem;
    height: 45px;
    margin-left: 2rem;
    margin-top: 10px;
    background-color: rgb(255, 255, 255);
    border-style: solid;
    border-width: 2px;
    border-color: rgb(0, 0, 0);
    display: flex;
    justify-content: center;
    font-family: "Open Sans", sans-serif;
    font-size: 20pt;
  }
  
  .placeholder-label{
    border-style: solid;
    border-width: 4px;
    border-color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    margin-right: 30px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }

  .font-label{
    width: 230px;
    height: 2.5rem;
    font-family: "Open Sans", sans-serif;
    font-size: 17pt;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-style: normal;
    text-align: left;
    line-height: 1.2;
    overflow-y: hidden;
  }

  .percentage-values{
    width: 198px;
    height: 3.5rem;
    font-family: "Open Sans", sans-serif;
    font-size: 20pt;
    margin-top: 20px;
    margin-left: 30px;
  }

  .margin-left{
    margin-left: 20px;
  }
  
  