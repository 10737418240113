.blue-right-arrow{
    width: 70px;
    height: 70px;
    background-image: url(../../../public/svg/blue-right-arrow.svg);
    margin-left: 40px;
}

.prefix-container{
    display: flex;
    flex-direction: row;
    padding: 20px;
   margin-top: 30px;
   margin-left: 60px;
   position:fixed;
}

.prefix-header{
    margin-top: 90px;
    margin-left: 11em;
}

.image-sm{
    width: 300px;
    height: 200px;
    position: absolute;
    left:74rem;
    top:4rem;
}

.textbox{
    height: 3.2rem;
    font-size: 30px;
    padding: 10px;
}

.form-font{
    height: 3.5rem;
    font-family: "Open Sans", sans-serif;
    font-size: 20pt;
    color: rgb(0, 0, 0);
    font-weight: bold;
    margin-left: 20rem;
}

.prefix-placeholder{
    width: 20rem;
    height: 45px;
    margin-left: 2rem;
    margin-top: 10px;
    background-color: rgb(255, 255, 255);
    border-style: solid;
    border-width: 2px;
    border-color: rgb(0, 0, 0);
}

.prefix-label{
    margin-left: 38px;
    margin-top: 10px;

}

.textarea-prefix{
    width: 67rem;
    height: 60px;
    border-style: solid;
    border-width: 2px;
    border-color: rgb(0, 18, 107);
    margin: -1px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10rem;
}

.padding-prefix{
    padding: 40px;
}

.margin-top{
    margin-top: 10px;
}

.margin-prefix {
    margin-left: 40px;
}

.scale{
    margin-right: 30px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    width: 198px;
    height: 2.5rem;
    font-family: "Open Sans", sans-serif;
    font-size: 20pt;
    font-weight: bold;
    font-style: normal;
    text-align: left;
    line-height: 1.2;
    overflow-y: hidden;
}

.block-none {
    border: none !important;
    background: none !important;
}

.margin-prefix2{
    margin-left: 50px;
}

#prefixLookup-field{
    height: 4.5rem;
    font-size: 30px;
}

.placeholder-per{
    border-style: solid;
    border-width: 4px;
    border-color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    margin-right: 30px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }

  .prefix-text{
    width: 29rem;
  }

  .start{
    margin-left: 10px;
  }