@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

*{
    font-family: "Open Sans", sans-serif;
    margin: 0;
    padding: 0;
}

.container {
    display: flex;
    justify-content: center;

    height: 100vh;
    background-color: rgb(219,219,219);
}

.image{
    width: 400px;
    height: 300px;
    padding: 40px;
}

.blue-button {
    background-color: rgb(0,134,208);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border-color: rgb(0, 113, 176);
    border-style: solid;
    border-width: 2px;
    border-radius: 10px;
    margin: -1px;
    margin: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;

}

a{
    text-decoration: none;
    font-family: "Open Sans", sans-serif;
    font-size: 15pt;
    color: rgb(255, 255, 255);
    font-weight:bold;
    font-style: normal;
    text-align: left;
    padding: 5px;
    line-height: 1.2;
    overflow-y: hidden;
}

.blue-button:hover{
    background-color: rgb(44, 76, 142);
    border-color: rgb(51, 141, 191);
}

.buttons{
    display: flex;
    flex-direction: column;
    margin-left: 40px;
}

.support-icon:hover {
    color: rgb(44, 76, 142);
  }

  .support-icon{
    font-size: 70px;
    position: absolute;
    top: 37rem;
    right: 3rem;
    color: rgb(131, 131, 131);
  }

  .logout-btn{
    font-size: 30px;
    position: absolute;
    right: 2rem;
    top: 1rem
  }

  .logout-btn:hover{
    background-color:  rgb(44, 76, 142);
    border-radius: 5px;
    color: aliceblue;
  }

  .support{
    display: flex;
    height: 100vh;
    font-family: "Open Sans", sans-serif;
    font-size: 30pt;
    font-weight:bold;
    font-style: normal;
    text-align: left;
    padding: 5px;
    line-height: 1.2;
    overflow-y: hidden;
  }

  .support-card{
  position: absolute;
  top:20rem;
  left:15rem
  }
  